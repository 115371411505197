exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categorias-tsx": () => import("./../../../src/pages/categorias.tsx" /* webpackChunkName: "component---src-pages-categorias-tsx" */),
  "component---src-pages-fale-conosco-tsx": () => import("./../../../src/pages/fale-conosco.tsx" /* webpackChunkName: "component---src-pages-fale-conosco-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-templates-category-category-tsx": () => import("./../../../src/templates/category/category.tsx" /* webpackChunkName: "component---src-templates-category-category-tsx" */),
  "component---src-templates-post-post-tsx": () => import("./../../../src/templates/post/post.tsx" /* webpackChunkName: "component---src-templates-post-post-tsx" */)
}

